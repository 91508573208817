import React from "react"

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
    <div style={{
        position: 'relative',
        paddingBottom: '56.25%',
        paddingTop: '30px',
        paddingRight: '2em',
        height: 0,
        overflow: 'hidden',
        zIndex:19
    }}>
        <div className="video">
            <video width="100%" controls autoPlay muted>
                <source src={videoSrcURL} type="video/mp4" />
                Your browser does not support the video tag!
            </video>
        </div>
    </div>
  )

export default Video
