import React from "react"

import Layout from "../components/layout"
import BGSlideshow from "../components/bg_slideshow"
import Video from "../components/video"
import SEO from "../components/seo"



import styles from "./index.module.scss"
import AL from "../images/alau.jpg"

import SL1 from "../images/Lau_2.jpg"
import SL2 from "../images/P1060399.jpg"
import SL3 from "../images/20160907_180128.jpg"
import SL4 from "../images/20170913_134523.jpg"
import SL5 from "../images/20170914_173932.jpg"
import SL6 from "../images/65569479_2309234605790923_8219389329374445568_o.jpg"
import SL7 from "../images/62500280_2277193692328348_9210242686903123968_o.jpg"



export default () => (
    <Layout allwaysTop={false} active="/">
        <SEO title="Schwertransport Lau" />


        <BGSlideshow style={{zIndex:-1}} entries={
            [
                {bgImage: SL1, animationDelay: 0, animationDuration: 42, xPercent: '100%', yPercent: '50%'},
                {bgImage: SL2, animationDelay: 6, animationDuration: 42, xPercent: '20%', yPercent: '50%'},
                {bgImage: SL3, animationDelay: 12, animationDuration: 42, xPercent: '0%', yPercent: '50%'},
                {bgImage: SL4, animationDelay: 18, animationDuration: 42, xPercent: '10%', yPercent: '50%'},
                {bgImage: SL5, animationDelay: 24, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL6, animationDelay: 30, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL7, animationDelay: 36, animationDuration: 42, xPercent: '0%', yPercent: '50%'},
            ]} />

        <article style={{background:'white'}}>
        <div className={styles.content_div} style={{marginTop: '100vh'}}>
            <h2>Herzlich Willkommen!</h2>
            <div>
                <img className={styles.content_img} src={AL} alt="André Lau" />
                <p>Als mittelständisches und inhabergeführtes Logistikunternehmen und Schwergutspedition mit Tradition und Leistungskraft sind wir Spezialisten für Herausforderungen.</p> 
                <p>Die Grundlagen für unseren und damit Ihren Erfolg bilden neben kompetenten Mitarbeitern, innovativer Technik und präziser Logistik auch unser komplexes Leistungsangebot, Flexibilität, Sicherheit und Zuverlassigkeit gegenüber Ihnen, unseren Kunden.</p>
                <p>Nicht nur der eigentliche Transport, sondern von der Planung bis zur Entladestelle - wenn nötig darüber hinaus - begleiten und unterstützen wir Sie, stehen mit Rat und Tat an Ihrer Seite.</p>
            </div>

        </div>

        <div className={styles.content_div_narrow} >
            <Video videoSrcURL="../../promo_video.mp4" videoTitle='Schwertransport Lau' />
        </div>

        </article>
    </Layout>
)